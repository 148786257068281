import React, { Fragment, useEffect } from "react"

import { Navbar } from "./navbar"
import { Footer } from "./footer"

const Layout = ({ children, header, footer }) => {
  const AOS = require("aos");

  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  });

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <Fragment>
      <Navbar header={header} />
        <main>{children}</main>
      <Footer footer={footer} />
    </Fragment>
  )
}

export default Layout
