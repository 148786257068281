import React, { Fragment, useContext } from "react";
import { globalHistory } from "@reach/router";
import * as classNames from 'classnames';
import { GlobalStateContext } from "../../context/GlobalContextProvider";

export const Footer = ({ footer, translateSlug }) => {
  const state = useContext(GlobalStateContext);
  const { language } = state;

  const { location } = globalHistory;

  return (
    <Fragment>
      <footer className="row">
        <div className="col-12">
          <div className="container-fluid footer-container">
            <div className="row py-3 row py-3">
              <div className="col-lg-6 col-12 justify-content-center pr-lg-2 justify-content-lg-end d-flex align-items-center">
                <p>
                  {footer.copyright}
                </p>
              </div>
              <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center justify-content-lg-start">
                <div className="d-flex pl-lg-2 flex-nowrap">
                  {footer.navItems && footer.navItems.length > 0 && footer.navItems.map((navItem) => {
                    return (
                      <a className={classNames("pr-2 pt-0", {' text-danger': location.pathname.indexOf(navItem.slug) > -1})} href={`/${language}/${navItem.slug}`}>
                        {navItem.label}
                      </a>
                    )
                  })} 
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}