import twitter from "../../images/twitter-icon.svg";
import React, { Fragment, useContext } from "react";
import { domain } from "../../utils";
import { GlobalStateContext } from "../../context/GlobalContextProvider";

import languageIconDe from '../../images/language-de-icon.svg';
import languageIconEn from '../../images/language-en-icon.svg';
import mobileSandwitcher from '../../images/mobile/navigation-icon-mobile.svg';
import mobileClose from '../../images/mobile/close-icon-mobile.svg';

export const Navbar = ({ header, translateSlug }) => {
  const state = useContext(GlobalStateContext);
  const { language } = state;

  const { logo, links } = header;

  return (
    <Fragment>
       <nav>
        {/* Navbar mobile */}
        <div className="container-fluid p-0">
          <div className="navbar-overlay" id="nav-mobile" style={{display: "block", width: "0%"}}>
            <div className="navbar-overlay-content">
              <nav className="navbar justify-content-end py-lg-y py-1 py-xxl-2">
                <a className="navbar-close p-1">
                  <img className="close-icon" src={mobileClose}/>
                </a>
              </nav>
              <div className="row mt-4">
                <div className="col-6 offset-3 d-block text-center">
                  {logo && logo.url && (
                    <>
                      <a href="/"></a><img src={`${domain}${logo.url}`}/>
                    </>
                  )}
                </div>
                {links && links.length > 0 && (
                  <div className="col-10 offset-1 d-block">
                    <ul className="d-block text-center p-0">
                      {links.map(({ label, slug }, index) => {
                        return (
                          <a className="d-block" href={`#${slug}`}>
                            <li className="text mt-4 mobileNavElement">
                              {label}
                            </li>
                          </a>
                        )
                      })}

                      <li className="d-flex justify-content-center pt-4">
                        {language === 'de' ? (
                            <a className="mr-2" href="/en/">
                              <img src={languageIconEn}/>
                            </a>
                        ) : (
                          <a className="mr-2" href="/de/">
                            <img src={languageIconDe}/>
                          </a>
                        )}
                        <a className="ml-2" href="https://twitter.com/minachatbot" target="_blank"><img src={twitter} alt="twitter"/></a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      
        <nav className="navbar navbar-expand-lg justify-content-between fixed-top bg-white py-lg-y py-2" id="navbar_main">
          <a className="navbar-brand" href={`/${language}`}> 
            {logo && logo.url && (
              <>
                <img className="logo" src={`${domain}${logo.url}`} alt="logo" />
              </>
            )}
          </a>
          <button 
            className="navbar-toggler pr-0" 
            type="button" 
            data-toggle="collapse" 
            data-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
          >
            <img className="navbar-toggler-icon" src={`${mobileSandwitcher}`} alt="logo" />
          </button>

          <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
            <div className="navbar-inner h-100" id="nav-desktop">
              {links && links.length > 0 && (
                <ul className="d-none d-md-inline navbar-inner-nav navbar-nav mr-auto p-0">
                  {links.map(({ label, slug }, index) => {
                    return (
                      <a href={`#${slug}`}>
                        <li className="d-inline m-1 m-md-2">
                          {label}
                        </li>
                      </a>
                    )
                  })}
                  {language === 'de' ? (
                    <a className="mx-1" href="/en"><img src={languageIconEn} alt="english"/></a>
                  ) : (
                    <a className="mx-1" href="/de"><img src={languageIconDe} alt="english"/></a>
                  )}
                  <a href="https://twitter.com/minachatbot" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter"/></a>
                </ul>
              )}
            </div>
          </div>
        </nav>
      </nav>
    </Fragment>
  )
}
