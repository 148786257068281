setTimeout( function() {

  
  const navbarToggler = document.querySelector('.navbar-toggler');
  const navDesktop = document.getElementById('nav-desktop');
  
  console.log(navbarToggler);
  
  function navbarHandler() {
    const mobileNav = document.getElementById('nav-mobile');
  
    if (mobileNav && (mobileNav.style.display === 'none' || mobileNav.style.display === '' || mobileNav.style.width === '0%')) {
      mobileNav.style.display = 'block';
      mobileNav.style.width = '100%';
  
      // This removes the scrolling of the default address bar of the browser, but also removes transition
      // mobileNav.style.width = '-moz-available';
      // mobileNav.style.width = '-webkit-fill-available';
      // mobileNav.style.width = 'fill-available';
      //-------------------------------------------
      // document.body.style.position = 'fixed';
      if (navDesktop) {
        navDesktop.style.display = 'none';
      }
    } else if (mobileNav && (mobileNav.style.display === 'block' || mobileNav.style.width === '0%')) {
      mobileNav.style.width = '0%';
      // document.body.style.position = '';
    }
  }
  
  // if (window.location.pathname !== '/' && window.location.pathname !== '/en/' && window.location.pathname !== '/en') {
  //   document.body.style.backgroundImage = 'none';
  //   if (navbarToggler) {
  //     navbarToggler.style.display = 'none';
  //   }
  // }
  
  function addShadowNavbar() {
    const navbarExpand = document.querySelector('.navbar-expand-lg');
    if (navbarExpand && (document.documentElement.scrollTop > 10)) {
      navbarExpand.classList.add('nav-shadow');
    } else {
      navbarExpand && navbarExpand.classList.remove('nav-shadow');
    }
  }
  
  function changeButtonColor() {
    const navbarExpand = document.querySelector('.btn-prototyp');
    if (navbarExpand && (document.documentElement.scrollTop > 10)) {
      navbarExpand.classList.add('btn-prototyp-dark');
    } else {
      navbarExpand && navbarExpand.classList.remove('btn-prototyp-dark');
    }
  }
  
  function removeNewsleterInput() {
    const newsletterInput = document.querySelectorAll('.header-input');
    const newsletterButton = document.querySelectorAll('.submit-button');
    newsletterInput.forEach((item) => {
      (item).style.display = 'none';
    });
    newsletterButton.forEach((item) => {
      (item).style.display = 'none';
    });
  }
  
  document.addEventListener('scroll', addShadowNavbar);
  document.addEventListener('scroll', changeButtonColor);
  
  
  if (navDesktop && (window.innerWidth < 750)) {
    navDesktop.style.display = 'none !important';
  }
  
  if (window.innerWidth < 750 && (window.location.pathname === '/en/' || window.location.pathname === '/en' || window.location.pathname === '/')) {
    const secondCarousel = document.getElementById('second-carousel');
    const desktopHeader = document.querySelector('.desktop-header');
  
  
    if (secondCarousel) {
      // (secondCarousel).setAttribute = (qualifiedName: 'class', value: 'w-75');
    }
    if (desktopHeader) {
      (desktopHeader).style.display = 'none';
    }
    const mobileContainer = document.querySelectorAll('.mobile-container');
    mobileContainer.forEach((item) => {
      item.setAttribute('class', 'carousel-item');
    });
    const desktopSlider = document.querySelector('.desktop-slider');
    if (desktopSlider) {
      (desktopSlider).style.display = 'none';
    }
  } else if (window.location.pathname === '/en/' || window.location.pathname === '/en' || window.location.pathname === '/') {
    const mobileHeader = document.querySelector('.mobile-header');
    const mobileSlider = document.querySelector('.mobile-slider');
    if (mobileHeader) {
      (mobileHeader).style.display = 'none';
    }
    if (mobileSlider) {
      (mobileSlider).style.display = 'none';
    }
  
    const submitButtons = document.querySelectorAll('.submit-button');
    submitButtons.forEach((item) => {
      item.addEventListener('click', () => {
        const subscrictionSections = document.querySelectorAll('.newsletter-subscription ');
        subscrictionSections.forEach((element) => {
          (element).style.display = 'none';
        });
        setTimeout(removeNewsleterInput, 1500);
      });
    });
  }
  
  var navbarClose = document.querySelector('.navbar-close');
  
  if (navbarToggler) {
    navbarToggler.addEventListener('click', navbarHandler);
  }
  if (navbarClose) {
    navbarClose.addEventListener('click', navbarHandler);
  }
  
  const mobileNavItem = document.querySelectorAll('.mobileNavElement');
  mobileNavItem.forEach((item) => {
    item.addEventListener('click', navbarHandler);
  });
}, 500) 


